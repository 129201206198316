import { useFormik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import {
  Col,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
  Row,
  FormCheck,
  Badge,
  Button,
  Toast,
} from "react-bootstrap";

import { DiDatabase } from "react-icons/di";
import { CiMenuBurger } from "react-icons/ci";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Modal from "react-modal";
import Select from "react-select";
import { FaUsers } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import {
  useCreateBidMutation,
  useGetUserQuery,
  // useGetNotificationsCountQuery,
  useGetAllDepartmentsQuery,
  useGetHealthProffessionalsMutation,
} from "../../store/api";
import MultiCascader from "./MultiCascader";
import dataItemsForTherapies from "./dataItemsForTherapies";
import "./style.scss";

import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Images } from "../../assets/images";
import RouteNames from "../../routes/RouteNames";
import { clearUser } from "../../store/reducers/authSlice"; // Update the import
import CustomButton from "../custombutton/CustomButton";
import CustomDropdown from "../customdropdown/CustomDropDown";
import { useGetAllInstitutesQuery } from "../../store/api";
import { useGetInstitutionalUsersMutation } from "../../store/api";
import socket from "../../store/actions/socket";
import { clearNotificationCount } from "../../store/reducers/notificationsSlice";
import { clearUserIdForTracking, trackEvent } from "../../utils/amplitude";
import { Events } from "../../utils/events";
// import { disconnectSocket } from "../../socket";
// import DiagnosisModal from './DiagnosisModal';

const Header = ({
  hasSearch,
  searchText,
  handleSearchChange,
  handleSidebarToggle,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("EN");
  const [setDropdownOpen] = useState(false);
  const [selectedMenux, setSelectedMenux] = useState("Data");
  const [expandedParents, setExpandedParents] = useState([]);
  const [containEpisode, setContainEpisode] = useState([]);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRequestModal, setIsOpenRequestModal] = useState(false);
  const notificationCount = useSelector(
    (state) => state?.notiCount?.notificationCount
  );
  const [isOpenLan, setIsOpenLan] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const dropdown1Ref = useRef(null);
  const dropdownLanguageRef = useRef(null);
  const [currentModal, setCurrentModal] = useState(0);
  const [previousModal, setPreviousModal] = useState(null);
  const [showStartEndYear, setShowStartEndYear] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState("DAYS");
  const [usernames, setUsernames] = useState([]);
  const [Institution, setInstitution] = useState([]);

  const { data: departmentsData } = useGetAllDepartmentsQuery();
  const [healthCare, { data: AllHealthProffessionals }] =
    useGetHealthProffessionalsMutation();

  // const onlyYears = Array.from(new Array(100), (val, index) => index + 1920); // Example year range

  const user = useSelector((state) => state.auth?.user);
  
  const initialValues = {
    StartdateofDiagnosis: "",
    EnddateofDiagnosis: "",
    StartYear: "",
    EndYear: "",
    postalCode: "",
    dataitem: [],
    addinstitution: "",
    institution: "",
    specifiedBuyers: [],
    lowerlimit: "",
    upperlimit: "",
    medical: "",
    general: [],
    dataCategory: [],
    gender: "",
    episode: [],
    customtitle: "",
    dataMeasure: [],
    startDateT: "",
    startDateFrom: "",
    startDateTo: "",
    endDateFrom: "",
    endDateTo: "",
    endDareT: "",
    source: [],
    description: "",
    duration: "days",
    price: 0,
    currency: "€",
    startdateForTherapiesPreviousLowerLimit: "",
    startdateForTherapiesPreviousUpperLimit: "",
    enddateForTherapiesPreviousLowerLimit: "",
    enddateForTherapiesPreviousUpperLimit: "",

    intervalCurrentTherapyAtLeast: "",
    // numberOfCurrentTherapyAtLeast: "",

    intervalCurrentTherapyMaximum: "",
    // numberOfCurrentTherapyMaximum: "",

    intervalPreviousTherapyAtLeast: "",
    numberOfPreviousTherapyAtLeast: "",
    intervalForPreviousTherapyMaximum: "",
    numberOfPreviousTherapyMaximum: "",
    reasonToStop: "",

    startdateForTherapiesCurrent: "",
    enddateForTherapiesCurrent: "",

    raceEthnicity: "",
    dateofdiagnosis: {
      min: {
        number: "",
        unit: "DAYS",
      },
      max: {
        number: "",
        unit: "DAYS",
      },
    },

    // date: '',
  };

  const onSubmit = async (values) => {
    if (!user && user?.role === "seller") {
      toast.error("You are not a Buyer");
    } else {
      console.log("values>>>>>>>>", values);

      const valuesToSend = {
        bidCategory: values.medical || undefined,
        diagnosis: values.episode || undefined,
        general: {
          allValues: values.general || undefined,
          birthyear: {
            startYear: values.StartYear || undefined,
            endYear: values.EndYear || undefined,
          },
          dateofdiagnosis: {
            startDate: values.StartdateofDiagnosis || undefined,
            endDate: values.EnddateofDiagnosis || undefined,
          },
          postalcode: values.postalCode || undefined,
          gender: values.gender || undefined,
          institution: values.institution ? [values.institution] : undefined,
          department: values?.department ? [values?.department] : undefined,
          specifiedBuyers: values?.HealthCareProfessional
            ? [values?.HealthCareProfessional]
            : undefined, // Include specifiedBuyers
        },
        dataItem: {
          specifiedValues: selectedTherapyData?.selectedOptions.map((option,index) => ({
            ...option,
            reason: values[`reasonToStop_${index}`]|| undefined,
          })),
          selectedValues: selectedTherapyData?.newSelectedOptions || undefined,
          parentSpecifiedValues:selectedTherapyData?.parentSpecifiedValues,
        },
        information: values.description || undefined,
        price: { amount: Number(values.price), currency: values.currency },
      };

      const cleanedSpecifiedValues = excludeProperties(
        valuesToSend.dataItem.specifiedValues,
        ["multiSelect", "children"]
      );

      console.log("Simple selected options:", valuesToSend);

      setIsOpenRequestModal(false);

      let cleanedDataToSend = {
        ...valuesToSend,
        dataItem: {
          ...valuesToSend.dataItem,
          specifiedValues: cleanedSpecifiedValues,
        },
        general: {
          ...valuesToSend.general,
          birthyear: {
            ...valuesToSend.general.birthyear,
          },
        },
      };

      // Conditionally remove endYear
      if (!showStartEndYear) {
        delete cleanedDataToSend.general.birthyear.endYear;
      }

      console.log("After Cleaning selected options:", cleanedDataToSend);

      if (user) {
        createBidMutation(cleanedDataToSend, {
          fixedCacheKey: "create-bid",
        });
      } else {
        localStorage.setItem("createdBid", JSON.stringify(cleanedDataToSend));
        setTimeout(() => {
          navigate(RouteNames.MyBids.route);
        }, 500);
      }

      formik.resetForm();
    }
  };

  // const { data, isSuccess, isError, refetch, error } =
  // useGetUserQuery(userData);
  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit,
  });

  // console.log("ForMik Values: ", formik.values);

  // const [getInstitutionalUsersMutation, { data: dataTwo }] =
  //   useGetInstitutionalUsersMutation();

  // console.log("UserNames Are : ", usernames);
  // console.log("Institution Are : ", Institution);

  // useEffect(() => {
  //   if (dataTwo && dataTwo?.length > 0) {
  //     const users = dataTwo.map((user) => ({
  //       label: user.username,
  //       value: user.id,
  //     }));
  //     setUsernames(users);
  //   }
  // }, [dataTwo]);

  const { data } = useGetAllInstitutesQuery();

  // console.log("Institutes are here:::::::", data);

  // {institutionNames: "Isala Zwolle",}

  useEffect(() => {
    if (formik.values?.institution || formik.values?.department) {
      const vts = {
        institutes: formik.values?.institution,
        department: formik?.values?.department,
      };
      healthCare(vts);
    }
  }, [formik.values?.institution, formik.values?.department]);

  // console.log("Institutional Users are here:::::::", dataTwo);

  const [selectedOptionPreviousTherapy, setSelectedOptionPreviousTherapy] =
    useState(null);
  const [selectedOptionCurrentTherapy, setSelectedOptionCurrentTherapy] =
    useState([]);

  // const { data: Notification } = useGetNotificationQuery();

  const handleCheckboxChange = (event) => {
    const { id } = event.target;

    setSelectedOptionPreviousTherapy(null);

    setSelectedOptionPreviousTherapy(id);
  };
  const handleCheckboxChangeCurrentTherapy = (event) => {
    const { id } = event.target;

    setSelectedOptionCurrentTherapy((prevSelected) => {
      if (prevSelected?.includes(id)) {
        // If the ID exists, remove it
        return prevSelected?.filter((optionId) => optionId !== id);
      } else {
        // If the ID doesn't exist, add it
        return [...prevSelected, id];
      }
    });
  };

  const handleParentToggle = (value) => {
    if (expandedParents.includes(value)) {
      setExpandedParents(expandedParents.filter((parent) => parent !== value));
    } else {
      setExpandedParents([...expandedParents, value]);
    }
  };

  // const handleEpisodeChange = (event) => {
  //   formik.handleChange(event);

  //   setCurrentModal(3);
  //   setPreviousModal(2);
  // };

  const handleEpisodeChange = (event) => {
    const { value } = event.target;
    console.log("Value is: ", value);

    let newEpisodeArray = [...formik.values.episode];
    if (newEpisodeArray.includes(value)) {
      newEpisodeArray = newEpisodeArray.filter((item) => item !== value);
    } else {
      newEpisodeArray.push(value);
    }

    formik.setFieldValue("episode", newEpisodeArray);
    console.log(newEpisodeArray); // Log the updated array
  };

  const handleMedicalChange = (event) => {
    formik.handleChange(event);

    setCurrentModal(2);
    setPreviousModal(1);
  };

  const handleUnitChange = (event) => {
    const newValue = event.target.value;
    setSelectedUnit(newValue);
    formik.setFieldValue("dateofdiagnosis.min.unit", newValue);
    formik.setFieldValue("dateofdiagnosis.max.unit", newValue);
  };

  const handleBack = () => {
    if (currentModal > 1) {
      setCurrentModal(currentModal - 1); // Switch to the previous modal
    }
  };

  const closeModal = () => {
    setSelectedTherapyData(null);
    setIsOpenRequestModal(false);
    setCurrentModal(null);
    setPreviousModal(null);

    formik.resetForm();
  };

  const handleNext = () => {
    if (currentModal === 4) {
      const combinationItems = selectedTherapyData?.selectedOptions?.filter(item => item.value.includes('Combination'));

        if (combinationItems.length === 1) {
          // Alert if only one combination is selected
          toast.error(
            'Please select more than one therapy in the Combination category before proceeding.'
          );
          return;
        } else {
      const containsTherapies = selectedTherapyData?.selectedOptions?.some(
        (option) => option.value.includes("Therapies")
      );

      const containsNoTherapies = selectedTherapyData?.selectedOptions?.some(
        (option) => option.value === "Therapies_No therapies"
      );

      if (!containsTherapies || containsNoTherapies) {
        
        const temp = selectedTherapyData?.selectedOptions?.map((option) => ({
          displayName: option.displayName ? option.displayName : option.label,
          value: option.value,
        }));
        selectedTherapyData.selectedOptions = temp;
        setCurrentModal(6);// Skip to the last modal if therapy data does not contain "Therapies" or is "Therapies_No therapies"
      
        return;
      } else {
        const temp = selectedTherapyData?.selectedOptions?.map((option) => ({
          displayName: option.displayName ? option.displayName : option.label,
          value: option.value,
        }));
        selectedTherapyData.selectedOptions = temp;
      }
    }
    }

    setCurrentModal(currentModal + 1); // Open the next modal

    console.log("selected therapy data", selectedTherapyData);
  };
  const handlePrevious = () => {
  
    if (currentModal === 6) {
        setCurrentModal(4);    
    }
    else{
      setCurrentModal(currentModal - 1); 

    }

    console.log("selected therapy data", selectedTherapyData);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    handleResize(); // Check initial size
    window.addEventListener("resize", handleResize); // Add resize listener

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up listener
    };
  }, []);

  const handleDataClick = () => {
    handleSidebarToggle("Data"); // Pass 'Data' or any identifier to determine which menu item is clicked
    setSelectedMenux("Data");
  };

  const handleUserClick = () => {
    handleSidebarToggle("User"); // Pass 'User' or any identifier to determine which menu item is clicked
    setSelectedMenux("Users");
  };

  // const toggleDropdown = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdownLan = () => {
    setIsOpenLan(!isOpenLan);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      if (dropdownMenuRef.current) {
        document.removeEventListener("click", handleOutsideClick);
      }
    };
  }, [dropdownMenuRef]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdown1Ref.current &&
        !dropdown1Ref.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      if (dropdown1Ref.current) {
        document.removeEventListener("click", handleOutsideClick);
      }
    };
  }, [dropdown1Ref]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownLanguageRef.current &&
        !dropdownLanguageRef.current.contains(event.target)
      ) {
        setIsOpenLan(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const dispatch = useDispatch();

  const tokenExpires = useSelector(
    (state) => state.auth?.user?.token?.refresh?.expires
  );
  useEffect(() => {
    const expirationDate = new Date(tokenExpires);
    const currentDate = new Date();
    if (currentDate > expirationDate) {
      dispatch(clearUser());
    }
  }, [user, tokenExpires, dispatch]);
  // console.log("hi", user);
  const handleLogout = useCallback(async () => {
    await trackEvent(Events.USER_LOGOUT);
    setTimeout(async () => {
      await clearUserIdForTracking();
      dispatch(clearUser());
      dispatch(clearNotificationCount(0));
      socket.disconnectSocket();
      toast.success("Log out successful");
      navigate(RouteNames.Default.route);
    }, 500);
  }, []);

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setDropdownOpen(false);
  };

  // const validationSchema = Yup.object().shape({
  //   // episode: Yup.string().nullable().required("Episode is required"),
  //   // medical: Yup.string().nullable().required("This option is required"),

  //   // // dateofdiagnosis: Yup.date().nullable().required('Date of Diagnosis is required'),
  //   // // birthYear: Yup.date().nullable().required('Birth Year is required'),
  //   // postalCode: Yup.string().nullable().required("Postal Code is required"),
  //   // // gender: Yup.string().nullable().required('Gender is required'),
  //   // // addinstitution: Yup.string().nullable().required('Institution is required'),
  //   // // lowerlimit: Yup.date().nullable().required('Lower Limit is required'),
  //   // // upperlimit: Yup.date().nullable().required('Upper Limit is required'),
  //   // // general: Yup.array()
  //   // //   .nullable()
  //   // //   .required('General is required')
  //   // //   .min(1, 'At least one option must be selected for General'),
  //   // // dataCategory: Yup.array().nullable().required('Data Category is required'),
  //   // description: Yup.string().nullable().required("Description is required"),
  //   price: Yup.number()
  //     .nullable()
  //     .required("Price is required")
  //     .positive("Price must be a positive number")
  //     .min(10, "Price must be at least 10 or greater"),
  // });

  useEffect(() => {
    // console.log("currentModal changed:", currentModal);
  }, [currentModal]);

  const [selectedTherapyData, setSelectedTherapyData] = useState([]);

  const [selectedValues, setSelectedValues] = useState([]);

  // console.log("SelectedTherapyData: ", selectedTherapyData);

  const [
    createBidMutation,
    {
      isSuccess: createBidSuccess,
      isError: bidCreateError,
      data: createBidData,
      error: createBidError,
    },
  ] = useCreateBidMutation();

  const navigate = useNavigate();

  // const {
  //   data: DataOfLoggedinUser,
  //   isSuccess: isSuccessDataUser,
  //   refetch: ref,
  // } = useGetUserQuery(user);

  // const { data: dataOfNotification, isSuccess: NotificationSuccess } =
  //   useGetNotificationsCountQuery();

  // useEffect(() => {
  //   if (NotificationSuccess) {
  //     console.log("Noti Count", dataOfNotification);
  //   }
  // }, [NotificationSuccess, dataOfNotification]);

  useEffect(() => {
    if (createBidSuccess) {
      toast.success("Request created");
      trackEvent(Events.USER_CREATE_REQUEST, createBidData);
      setTimeout(() => {
        navigate(RouteNames.MyBids.route);
      }, 500);
    } else if (bidCreateError) {
      trackEvent(
        Events.USER_CREATE_REQUEST_ERROR,
        createBidError?.data?.message
      );
      toast.error("Request not created");
    }
  }, [createBidSuccess, bidCreateError]);

  const excludeProperties = (array, keys) => {
    return array.map((item) => {
      const newItem = { ...item };
      keys.forEach((key) => delete newItem[key]);
      return newItem;
    });
  };

  // console.log("Values Are: ", formik.values.episode);
  useEffect(() => {
    setContainEpisode(formik?.values?.episode);
  }, [formik?.values?.episode, currentModal]);

  const generalOptions = [
    { value: "Country", label: "Country" },
    { value: "Institution", label: "Institution" },
    { value: "Department", label: "Department" },
    { value: "Health Care Professional", label: "Health Care Professional" },
    { value: "birthYear", label: "Birth Year" },
    { value: "Disease Duration", label: "Disease Duration" },
    { value: "gender", label: "Gender" },
    { value: "postalCode", label: "Postal Code" },
    { value: "Race / Ethnicity", label: "Race / Ethnicity" },
    { value: "Living situation", label: "Living situation" },
    { value: "Annual household income", label: "Annual household income" },
    {
      value: "Highest level of Education",
      label: "Highest level of Education",
    },
    { value: "Employment status", label: "Employment status" },
  ];

  const genderOption = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  const handleSelect = (selectedTherapyData) => {
    const selectedValues = selectedTherapyData?.selectedOptions?.flatMap(
      (option) => option.value
    );
    setSelectedTherapyData(selectedTherapyData);
    setSelectedValues(selectedValues);

    console.log("selectedTherapyData", selectedTherapyData);
  };

  const [wordCount, setWordCount] = useState(0);

  const MAX_WORDS = 150;
  const handleInput = (e) => {
    const words = e.target.value.split(/\s+/).filter((word) => word !== "");

    if (words.length <= MAX_WORDS) {
      setWordCount(words.length);
    } else {
      // If the user exceeds the word limit, truncate the input to 150 words
      e.target.value = words.slice(0, MAX_WORDS).join(" ");
      setWordCount(MAX_WORDS);
    }
  };

  const years = [];
  const currentYear = new Date().getFullYear();
  const startYear = 1950;
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }

  const therapyData = dataItemsForTherapies();

  const handleRequestClick = () => {
    setIsOpenRequestModal(true);
    setCurrentModal(1);
  };
  const [modalWidth, setModalWidth] = useState("30vw");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        // adjust the breakpoint as needed
        setModalWidth("76vw");
      } else {
        setModalWidth("30vw");
      }
    };

    window.addEventListener("resize", handleResize);

    // Call handleResize once on mount to set the initial width
    handleResize();

    // Clean up the event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const raceEthnicityOptions = [
    { label: "White", value: "white" },
    { label: "Black/African American", value: "Black/African American" },
    { label: "Asian", value: "Asian" },
    { label: "Hispanic/Latino", value: "Hispanic/Latino" },
    { label: "Native Hawaiian", value: "Native Hawaiian" },
    { label: "Other Pacific Islander", value: "Other Pacific Islander" },
  ];

  const LivingSituationItems = [
    {
      label: "Married/co-habiting",
      value: "Married/co-habiting",
    },
    {
      label: "Never married/divorced/widowed",
      value: "Never married/divorced/widowed",
    },
  ];
  const AnnualHouseHoldItems = [
    { label: "<15,000", value: "Below15,000" },
    { label: "15,000 – 30,000", value: "15,000 – 30,000" },
    { label: "30,000 – 50,000", value: "30,000 – 50,000" },
    { label: "50,000 – 100,000", value: "50,000 – 100,000" },
    { label: ">100,000", value: "Above100,000" },
  ];

  const HighestLevelOfeducationItems = [
    { label: "<12 years", value: "Below 12 years" },
    {
      label: "High school diploma",
      value: "High school diploma",
    },
    {
      label: "Associate’s or technical degree",
      value: "Associate’s or technical degree",
    },
    {
      label: "Bachelor’s degree",
      value: "Bachelor’s degree",
    },
    { label: "Post-Graduate", value: "Post-Graduate" },
  ];

  const HealthInsuranceStatusItems = [
    { label: "Private", value: "Private" },
    { label: "Public", value: "Public" },
    { label: "None", value: "None" },
  ];

  const EmploymentStatusItems = [
    { label: "Employed", value: "employed" },
    { label: "Not-employed", value: "not-employed" },
  ];

  const InstitutionItems =
    data?.map((institution) => ({
      label: institution,
      value: institution,
    })) || [];

  // console.log(">>>>>>>>", option);

  const renderModalContent = () => {
    return (
      <Form onSubmit={formik.handleSubmit}>
        {currentModal === 1 && (
          <div className="Buy_form">
            <Row>
              <Col xs={12}>
                <h2
                  style={{
                    textAlign: "left",
                    marginTop: "25px",
                    marginLeft: "25px",
                    fontWeight: "bold",
                  }}
                >
                  Data
                </h2>
                <Form.Group className="form-group ">
                  <Form.Label
                    style={{ fontWeight: "normal", marginLeft: "25px" }}
                  >
                    Select one
                  </Form.Label>

                  <div>
                    <Form.Check
                      type="radio"
                      id="medicalMedical"
                      name="medical"
                      label="Medical"
                      value="Medical"
                      style={{ marginLeft: "40px" }}
                      onChange={handleMedicalChange}
                      checked={formik.values.medical === "Medical"}
                    />
                    <Form.Check
                      type="radio"
                      id="medicalLocation"
                      name="medical"
                      label="Location"
                      value="Location"
                      style={{ marginLeft: "40px" }}
                      onChange={handleMedicalChange}
                      checked={formik.values.medical === "Location"}
                    />
                  </div>
                  {formik.touched.medical && formik.errors.medical ? (
                    <div className="error">{formik.errors.medical}</div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </div>
        )}
        {currentModal === 2 && (
          <div className="Buy_form">
            <Row>
              <Col xs={12}>
                <>
                <div className="d-flex">
                <h5
                  style={{
                    paddingTop: "10px",
                    paddingRight: "10px",
                    color: "#5B99A6",
                    cursor: "pointer",
                  }}
                  onClick={handlePrevious}
                >
                 {'<'} Back
                </h5>
                  <h2
                    style={{
                      textAlign: "left",
                      // marginBottom: "20px",
                      paddingTop: "3px",
                      marginLeft: "20px",
                      overflow: "hidden !important",
                      fontWeight: "bold",
                    }}
                  >
                    Diagnosis
                  </h2>
                  </div>
                  <Form.Group className="form-group mb-3">
                    <div>
                      <Form.Label
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        Select one
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        id="episodeHealthy"
                        name="episode"
                        value="Healthy"
                        style={{ marginLeft: "35px" }}
                        onChange={handleEpisodeChange}
                        checked={formik.values.episode.includes("Healthy")}
                        label="Healthy"
                      />
                      <Form.Check
                        type="checkbox"
                        id="episodeRRMS"
                        name="episode"
                        value="Multiple Sclerosis – Relapsing Remitting"
                        style={{ marginLeft: "35px" }}
                        onChange={handleEpisodeChange}
                        checked={formik.values.episode.includes(
                          "Multiple Sclerosis – Relapsing Remitting"
                        )}
                        label="Multiple Sclerosis – Relapsing Remitting"
                      />
                      <Form.Check
                        type="checkbox"
                        id="episodeSPMS"
                        name="episode"
                        value="Multiple Sclerosis – Secondary Progressive"
                        style={{ marginLeft: "35px" }}
                        onChange={handleEpisodeChange}
                        checked={formik.values.episode.includes(
                          "Multiple Sclerosis – Secondary Progressive"
                        )}
                        label="Multiple Sclerosis – Secondary Progressive"
                      />
                      <Form.Check
                        type="checkbox"
                        id="episodePPMS"
                        name="episode"
                        value="Multiple Sclerosis – Primary Progressive"
                        style={{ marginLeft: "35px" }}
                        onChange={handleEpisodeChange}
                        checked={formik.values.episode.includes(
                          "Multiple Sclerosis – Primary Progressive"
                        )}
                        label="Multiple Sclerosis – Primary Progressive"
                      />
                      <Form.Check
                        type="checkbox"
                        id="episodeT2D"
                        name="episode"
                        value="Type 2 Diabetes"
                        style={{ marginLeft: "35px" }}
                        onChange={handleEpisodeChange}
                        checked={formik.values.episode.includes(
                          "Type 2 Diabetes"
                        )}
                        label="Type 2 Diabetes"
                      />
                      <Form.Check
                        type="checkbox"
                        id="episodeMG"
                        name="episode"
                        value="Myasthenia Gravis"
                        style={{ marginLeft: "35px" }}
                        onChange={handleEpisodeChange}
                        checked={formik.values.episode.includes(
                          "Myasthenia Gravis"
                        )}
                        label="Myasthenia Gravis"
                      />
                      <Form.Check
                        type="checkbox"
                        id="episodeOthers"
                        name="episode"
                        value="Others"
                        style={{ marginLeft: "35px" }}
                        onChange={handleEpisodeChange}
                        checked={formik.values.episode.includes("Others")}
                        label="Others"
                      />
                    </div>
                    {formik.touched.episode && formik.errors.episode ? (
                      <div className="error">{formik.errors.episode}</div>
                    ) : null}
                  </Form.Group>
                </>
              </Col>
            </Row>
          </div>
        )}

        {currentModal === 3 && (
          <div className="Buy_form">
            <Row>
              <Col xs={12}>
                <Form.Group className="general" style={{ marginBottom: "7vh" }}>
                  <div
                    style={{
                      display: "flex",

                      flexDirection: "row",
                      // justifyContent: "space-between",
                    }}
                  ><h5
                  style={{
                    paddingTop: "10px",
                    paddingRight: "10px",
                    color: "#5B99A6",
                    cursor: "pointer",
                  }}
                  onClick={handlePrevious}
                >
                 {'<'} Back 
                </h5>
                    <h2
                      style={{
                        textAlign: "left",
                        marginBottom: "20px",
                        fontWeight: "bold",
                        display: "inline-block",
                      }}
                    >
                        General
                    </h2>
                    {/* <h5
                      style={{
                        paddingTop: "10px",
                        paddingRight: "10px",
                        color: "#5B99A6",
                        cursor: "pointer",
                      }}
                      onClick={handleNext}
                    >
                      {/* Skip *}
                    </h5> */}
                  </div>
                  <Form.Label
                    style={{ fontWeight: "normal", marginLeft: "25px" }}
                  >
                    Choose Multiple
                  </Form.Label>

                  {generalOptions.map((option) => (
                    <Form.Check
                      key={option.value}
                      style={{ marginLeft: "25px" }}
                      type="checkbox"
                      id={option.value}
                      name="general"
                      value={option.value}
                      label={option.label}
                      onChange={formik.handleChange}
                      checked={formik.values.general.includes(option.value)}
                    />
                  ))}
                  <hr />

                  {formik.touched.source && formik.errors.source ? (
                    <div className="error">{formik.errors.source}</div>
                  ) : null}
                </Form.Group>
              </Col>

              {formik.values.general.includes("Disease Duration") && (
                <>
                  <Col xs={12} lg={12}>
                    <div className="therapy-dates-container">
                      <Form.Label style={{ fontWeight: "bold" }}>
                        At least
                      </Form.Label>
                      <Row>
                        <Col xs={6}>
                          <Form.Group className="form-group mb-3">
                            <Form.Label>Interval</Form.Label>
                            <Form.Select
                              name="dateofdiagnosis.min.unit"
                              id="dateofdiagnosis.min.unit"
                              onChange={(e) => {
                                handleUnitChange(e);
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.dateofdiagnosis.min.unit}
                            >
                              <option value="DAYS">Days</option>
                              <option value="WEEKS">Weeks</option>
                              <option value="MONTHS">Months</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group className="form-group mb-3">
                            <Form.Label>Number of {selectedUnit}</Form.Label>
                            <Form.Control
                              id="dateofdiagnosis.min.number"
                              name="dateofdiagnosis.min.number"
                              type="number"
                              value={formik.values.dateofdiagnosis.min.number}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Form.Label style={{ fontWeight: "bold" }}>
                        Maximum
                      </Form.Label>

                      <Row>
                        <Col xs={6}>
                          <Form.Group className="form-group mb-3">
                            <Form.Label>Interval</Form.Label>
                            <Form.Select
                              name="dateofdiagnosis.max.unit"
                              id="dateofdiagnosis.max.unit"
                              onChange={(e) => {
                                handleUnitChange(e);
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.dateofdiagnosis.max.unit}
                            >
                              <option value="DAYS">Days</option>
                              <option value="WEEKS">Weeks</option>
                              <option value="MONTHS">Months</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group className="form-group mb-3">
                            <Form.Label>Number of {selectedUnit}</Form.Label>
                            <Form.Control
                              id="dateofdiagnosis.max.number"
                              name="dateofdiagnosis.max.number"
                              type="number"
                              value={formik.values.dateofdiagnosis.max.number}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </>
              )}

              {formik.values.general.includes("gender") && (
                <Col lg={6} xs={12}>
                  <Form.Group className="gender mb-3">
                    <Form.Label>Gender: </Form.Label>
                    <Select
                      id="gender"
                      name="gender"
                      options={genderOption}
                      isMulti
                      value={genderOption.filter((option) =>
                        formik.values.gender.includes(option.value)
                      )}
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions?.map(
                          (option) => option.value
                        );
                        // Update the formik state with the selected values
                        formik.setFieldValue("gender", selectedValues);
                      }}
                      onBlur={formik.handleBlur}
                      closeMenuOnSelect={false}
                      styles={{
                        container: (base) => ({
                          ...base,
                          width: "100%",
                          marginBottom: "10px",
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>
              )}
              {formik.values.general.includes("Country") && (
                <Col lg={6} xs={12}>
                  <Form.Group className="form-group mb-3">
                    <Form.Label>Country:</Form.Label>
                    <Form.Select
                      id="Country"
                      name="Country"
                      value={formik.values.Country}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      <option value="Netherlands">Netherlands</option>
                    </Form.Select>

                    {formik.touched.Country && formik.errors.Country ? (
                      <div className="error">{formik.errors.Country}</div>
                    ) : null}
                  </Form.Group>
                </Col>
              )}

              {formik.values.general.includes("postalCode") && (
                <Col lg={6} xs={12}>
                  <Form.Group className="form-group mb-3">
                    <Form.Label>Postal Code: </Form.Label>
                    <Form.Control
                      id="postalCode"
                      name="postalCode"
                      value={formik.values.postalCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></Form.Control>

                    {formik.touched.postalCode && formik.errors.postalCode ? (
                      <div className="error">{formik.errors.postalCode}</div>
                    ) : null}
                  </Form.Group>
                </Col>
              )}

              {formik.values.general.includes("birthYear") && (
                <Col xs={12} className="therapy-dates-container">
                  <h4 className="therapy-dates-heading">Birth year dates</h4>
                  <hr />
                  <h5
                    style={{
                      paddingTop: "5px",
                      paddingRight: "5px",
                      color: "#5B99A6",
                      cursor: "pointer",
                      textAlign: "end",
                      fontSize: "18px",
                    }}
                    onClick={() => setShowStartEndYear(!showStartEndYear)}
                  >
                    Set Range
                  </h5>
                  <Form.Group className="form-group mb-3">
                    <Form.Label> Start Year </Form.Label>
                    <Form.Control
                      as="select"
                      id="StartYear"
                      name="StartYear"
                      value={formik.values.StartYear}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Select Start Year</option>
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Form.Control>

                    <Form.Label>End Year</Form.Label>
                    <Form.Control
                      as="select"
                      id="EndYear"
                      name="EndYear"
                      value={formik.values.EndYear}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Select End Year</option>
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Form.Control>

                    {formik.touched.StartYear && formik.errors.StartYear ? (
                      <div className="error">{formik.errors.StartYear}</div>
                    ) : null}
                  </Form.Group>
                  {showStartEndYear ? (
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Start Year</Form.Label>
                      <Form.Control
                        as="select"
                        id="StartYear"
                        name="StartYear"
                        value={formik.values.StartYear}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Start Year</option>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Form.Control>

                      <Form.Label>End Year</Form.Label>
                      <Form.Control
                        as="select"
                        id="EndYear"
                        name="EndYear"
                        value={formik.values.EndYear}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select End Year</option>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Form.Control>

                      {formik.touched.StartYear && formik.errors.StartYear ? (
                        <div className="error">{formik.errors.StartYear}</div>
                      ) : null}

                      {formik.touched.EndYear && formik.errors.EndYear ? (
                        <div className="error">{formik.errors.EndYear}</div>
                      ) : null}
                    </Form.Group>
                  ) : (
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Birth Year</Form.Label>
                      <Form.Control
                        as="select"
                        id="StartYear"
                        name="StartYear"
                        value={formik.values.StartYear}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Birth Year</option>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Form.Control>

                      {formik.touched.StartYear && formik.errors.StartYear ? (
                        <div className="error">{formik.errors.StartYear}</div>
                      ) : null}
                    </Form.Group>
                  )}
                </Col>
              )}

              {formik.values.general.includes("Race / Ethnicity") && (
                <Col lg={6} xs={12}>
                  <Form.Group className="form-group mb-3">
                    <Form.Label>Race / Ethnicity </Form.Label>

                    <CustomDropdown
                      id="raceEthnicity"
                      name="raceEthnicity"
                      options={raceEthnicityOptions}
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              )}
              {formik.values.general.includes("Living situation") && (
                <Col lg={6} xs={12}>
                  <Form.Group className="form-group mb-3">
                    <Form.Label>Living situation </Form.Label>

                    <CustomDropdown
                      id="LivingSituation"
                      name="LivingSituation"
                      options={LivingSituationItems}
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              )}
              {formik.values.general.includes("Annual household income") && (
                <Col lg={6} xs={12}>
                  <Form.Group className="form-group mb-3">
                    <Form.Label>Annual household income </Form.Label>

                    <CustomDropdown
                      id="Annual household income"
                      name="Annual household income"
                      options={AnnualHouseHoldItems}
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              )}
              {formik.values.general.includes("Highest level of Education") && (
                <Col lg={6} xs={12}>
                  <Form.Group className="form-group mb-3">
                    <Form.Label>Highest level of Education </Form.Label>

                    <CustomDropdown
                      id="Highest level of Education"
                      name="Highest level of Education"
                      options={HighestLevelOfeducationItems}
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              )}

              {formik.values.general.includes("Employment status") && (
                <Col lg={6} xs={12}>
                  <Form.Group className="form-group mb-3">
                    <Form.Label>Employment status </Form.Label>

                    <CustomDropdown
                      id="Employment status"
                      name="Employment status"
                      options={EmploymentStatusItems}
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              )}
              {formik.values.general.includes("Institution") && (
                <Col lg={6} xs={12}>
                  <Form.Group className="form-group mb-3">
                    <Form.Label>Institution:</Form.Label>
                    <Form.Select
                      id="institution"
                      name="institution"
                      value={formik.values.institution}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" disabled>
                        Institution
                      </option>
                      {InstitutionItems?.map((i) => (
                        <option value={`${i.value}`}>{i.label}</option>
                      ))}
                    </Form.Select>
                    {formik.touched.institution && formik.errors.institution ? (
                      <div className="error">{formik.errors.institution}</div>
                    ) : null}
                  </Form.Group>

                  {/* <Form.Group className="form-group mb-3">
                    <Form.Label>Institution</Form.Label>
                    <CustomDropdownTwo
                      id="institution"
                      name="institution"
                      Institution={Institution}
                      setInstitution={setInstitution}
                      options={InstitutionItems}
                      formik={formik}
                      isMulti={false} // Enable multi-select
                    />
                  </Form.Group> */}
                  {/* {Institution.length > 0 && (
                    <Col lg={15} xs={12}>
                      <Form.Group className="form-group mb-3">
                        <Form.Label>Usernames</Form.Label>
                        <Select
                          id="specifiedBuyers"
                          name="specifiedBuyers"
                          options={usernames}
                          isMulti={true}
                          value={usernames.filter((option) =>
                            formik.values.specifiedBuyers.includes(option.value)
                          )}
                          onChange={(selectedOptions) => {
                            const selectedIds = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];
                            formik.setFieldValue(
                              "specifiedBuyers",
                              selectedIds
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </Form.Group>
                    </Col>
                  )} */}
                </Col>
              )}
              {formik.values.general.includes("Department") && (
                <Col lg={6} xs={12}>
                  <Form.Group className="form-group mb-3">
                    <Form.Label>Department:</Form.Label>
                    <Form.Select
                      id="department"
                      name="department"
                      value={formik.values.department}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" disabled selected>
                        Department
                      </option>
                      {departmentsData &&
                        departmentsData.map((department, index) => (
                          <option value={`${department}`}>{department}</option>
                          // <option key={index} value={department}>
                          //   {department}
                          // </option>
                        ))}
                    </Form.Select>
                    {formik.touched.department && formik.errors.department ? (
                      <div className="error">{formik.errors.department}</div>
                    ) : null}
                  </Form.Group>
                </Col>
              )}
              {formik.values.general.includes("Health Care Professional") &&
                (formik.values.general.includes("Department") ||
                  formik.values.general.includes("Institution")) && (
                  <Col lg={12} xs={12}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Username </Form.Label>

                      <Form.Select
                        id="Health Care Professional"
                        name="HealthCareProfessional"
                        value={formik.values.HealthCareProfessional}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="" disabled selected>
                          Username
                        </option>
                        {AllHealthProffessionals &&
                          AllHealthProffessionals.map(
                            (Proffessional, index) => (
                              <option
                                value={`${Proffessional?.id || Proffessional?._id
                                  }`}
                              >
                                {Proffessional?.username}
                              </option>
                            )
                          )}
                      </Form.Select>
                      {formik.touched.department && formik.errors.department ? (
                        <div className="error">{formik.errors.department}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                )}
            </Row>
          </div>
        )}

        {currentModal === 4 && (
          <div className="Buy_form">
            <Row>
              <Col xs={12}>
                <Form.Group className="form-group mb-3">
                  <div className="multi">
                  <h5
                  style={{
                    paddingTop: "6px",
                    paddingRight: "10px",
                    color: "#5B99A6",
                    cursor: "pointer",
                    position:'absolute'
                  }}
                  onClick={handlePrevious}
                >
                 {'<'} Back
                </h5>
                    <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                      Request
                    </h2>

                    <MultiCascader
                      options={therapyData}
                      onSelect={handleSelect}
                      initialSelectedData={selectedTherapyData}
                      selectedDiagnosis={containEpisode}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </div>
        )}

        {currentModal === 5 && (
          <div className="Buy_form">
            <>
            <h5
                  style={{
                    paddingTop: "10px",
                    paddingRight: "10px",
                    color: "#5B99A6",
                    cursor: "pointer",
                  }}
                  onClick={handlePrevious}
                >
                 {'<'} Back
                </h5>
              <Row>
                {selectedTherapyData?.selectedOptions?.map((item, index) => {
                  if (
                    (item?.value.includes("Current") ||
                      item?.value.includes("Previous")) &&
                    !item?.value.includes("No other")
                  ) {
                    return (
                      <Col xs={12}>
                        <div className="therapy-dates-container">
                          <h4 className="therapy-dates-heading">
                            {item?.displayName}
                          </h4>
                          <Form.Group className="form-group mb-3">
                            <FormCheck
                              type="checkbox"
                              id={`setByDateCheckboxCurrentTherapy_${index}`}
                              name={`setByDateCheckboxCurrentTherapy_${index}`}
                              label="Set by start/end date"
                              onChange={handleCheckboxChangeCurrentTherapy}
                              checked={selectedOptionCurrentTherapy?.includes(
                                `setByDateCheckboxCurrentTherapy_${index}`
                              )}
                            />
                            <FormCheck
                              type="checkbox"
                              id={`setTimeCheckboxCurrentTherapy_${index}`}
                              name={`setTimeCheckboxCurrentTherapy_${index}`}
                              label="Set time on therapy"
                              onChange={handleCheckboxChangeCurrentTherapy}
                              checked={selectedOptionCurrentTherapy?.includes(
                                `setTimeCheckboxCurrentTherapy_${index}`
                              )}
                            />
                            <FormCheck
                              type="checkbox"
                              id={`setTimeToTherapyCheckboxCurrentTherapy_${index}`}
                              name={`setTimeToTherapyCheckboxCurrentTherapy_${index}`}
                              label="Set time to therapy"
                              onChange={handleCheckboxChangeCurrentTherapy}
                              checked={selectedOptionCurrentTherapy?.includes(
                                `setTimeToTherapyCheckboxCurrentTherapy_${index}`
                              )}
                            />
{item?.value.includes("Previous") && (
                            <FormCheck
                              type="checkbox"
                              id={`giveReasonCheckboxCurrentTherapy_${index}`}
                              name={`giveReasonCheckboxCurrentTherapy_${index}`}
                              label="Stop Reason"
                              onChange={(e) => {
                                handleCheckboxChangeCurrentTherapy(e);
                                // Toggle the "Reason to Stop" dropdown based on checkbox state
                                formik.setFieldValue(`showReasonDropdown_${index}`, e.target.checked);
                              }}
                              checked={formik.values[`showReasonDropdown_${index}`] || false}
                            />)}

                            {formik.values[`showReasonDropdown_${index}`] && (
                              <Form.Group controlId={`reasonToStop_${index}`} style={{ marginTop: '1rem' }}>
                                <Form.Label style={{ fontWeight: 'bold' }}>Reason to Stop</Form.Label>
                                <Form.Select
                                  name={`reasonToStop_${index}`}
                                  value={formik.values[`reasonToStop_${index}`] || ''}
                                  onChange={formik.handleChange}
                                >
                                  <option value="" disabled>
                                    Select reason
                                  </option>
                                  <option value="Side effect">Side effect</option>
                                  <option value="Disease Progression">Disease Progression</option>
                                </Form.Select>
                              </Form.Group>
                            )}


                            {/* Conditionally render the "Reason to Stop" text field */}
                            {/* {item?.value.includes("Previous") && (
                              <Form.Group controlId={`reasonToStop_${index}`}>
                                <Form.Label style={{ fontWeight: 'bold', marginTop: '1rem' }}>Reason to Stop</Form.Label>
                                <Form.Select
                                  name={`reasonToStop_${index}`}
                                  value={formik.values[`reasonToStop_${index}`] || ''}
                                  onChange={formik.handleChange}
                                >
                                  <option value="" disabled>
                                    Select reason
                                  </option>
                                  <option value="Side effect">Side effect</option>
                                  <option value="Disease Progression">Disease Progression</option>
                                </Form.Select>
                              </Form.Group>
                            )} */}





                          </Form.Group>

                          {selectedOptionCurrentTherapy?.includes(
                            `setTimeCheckboxCurrentTherapy_${index}`
                          ) &&
                            "setTimeCheckboxCurrentTherapy" && (
                              <div className="therapy-dates-container">
                                <div>
                                  <Form.Label style={{ fontWeight: "900" }}>
                                    Time on therapy
                                  </Form.Label>
                                </div>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  At least
                                </Form.Label>

                                <Row>
                                  <Col xs={6}>
                                    <Form.Group className="form-group mb-3">
                                      <Form.Label>Interval</Form.Label>
                                      <Form.Select
                                        name={`intervalCurrentTherapyAtLeast_${index}`}
                                        id={`intervalCurrentTherapyAtLeast_${index}`}
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          const newUnit = e.target.value;

                                          // Set the unit for both min and max when 'At least' interval is changed
                                          setSelectedTherapyData(
                                            (prevData) => ({
                                              ...prevData,
                                              selectedOptions:
                                                prevData.selectedOptions.map(
                                                  (
                                                    { label, parent, ...rest },
                                                    i
                                                  ) =>
                                                    i === index
                                                      ? {
                                                        ...rest,
                                                        min: {
                                                          ...rest.min,
                                                          unit: newUnit,
                                                        },
                                                        max: {
                                                          ...rest.max,
                                                          unit: newUnit, // Synchronize max unit
                                                        },
                                                      }
                                                      : { ...rest }
                                                ),
                                            })
                                          );

                                          // Update formik value for Maximum interval
                                          formik.setFieldValue(
                                            `intervalCurrentTherapyMaximum_${index}`,
                                            newUnit
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                          formik.values[
                                          `intervalCurrentTherapyAtLeast_${index}`
                                          ]
                                        }
                                      >
                                        <option value="DAYS">Days</option>
                                        <option value="WEEKS">Weeks</option>
                                        <option value="MONTHS">Months</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col xs={6}>
                                    <Form.Group className="form-group mb-3">
                                      <Form.Label>
                                        Number of{" "}
                                        {
                                          formik.values[
                                          `intervalCurrentTherapyAtLeast_${index}`
                                          ]
                                        }
                                      </Form.Label>
                                      <Form.Control
                                        id={`numberOfCurrentTherapyAtLeast_${index}`}
                                        name={`numberOfCurrentTherapyAtLeast_${index}`}
                                        type="number"
                                        value={
                                          formik.values[
                                          `numberOfCurrentTherapyAtLeast_${index}`
                                          ]
                                        }
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          const newCount = e.target.value;
                                          setSelectedTherapyData(
                                            (prevData) => ({
                                              ...prevData,
                                              selectedOptions:
                                                prevData.selectedOptions.map(
                                                  (
                                                    { label, parent, ...rest },
                                                    i
                                                  ) =>
                                                    i === index
                                                      ? {
                                                        ...rest,
                                                        min: {
                                                          ...rest.min,
                                                          count: newCount,
                                                          unit: formik.values[
                                                            `intervalCurrentTherapyMaximum_${index}`
                                                          ]
                                                            ? formik.values[
                                                            `intervalCurrentTherapyMaximum_${index}`
                                                            ]
                                                            : "DAYS",
                                                        },
                                                      }
                                                      : { ...rest }
                                                ),
                                            })
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Form.Label style={{ fontWeight: "bold" }}>
                                  Maximum
                                </Form.Label>

                                <Row>
                                  <Col xs={6}>
                                    <Form.Group className="form-group mb-3">
                                      <Form.Label>Interval</Form.Label>
                                      <Form.Select
                                        name={`intervalCurrentTherapyMaximum_${index}`}
                                        id={`intervalCurrentTherapyMaximum_${index}`}
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          const newUnit = e.target.value;
                                          setSelectedTherapyData(
                                            (prevData) => ({
                                              ...prevData,
                                              selectedOptions:
                                                prevData.selectedOptions.map(
                                                  (
                                                    { label, parent, ...rest },
                                                    i
                                                  ) =>
                                                    i === index
                                                      ? {
                                                        ...rest,
                                                        max: {
                                                          ...rest.max,
                                                          unit: newUnit,
                                                        },
                                                      }
                                                      : { ...rest }
                                                ),
                                            })
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                          formik.values[
                                          `intervalCurrentTherapyMaximum_${index}`
                                          ]
                                        }
                                      >
                                        <option value="DAYS">Days</option>
                                        <option value="WEEKS">Weeks</option>
                                        <option value="MONTHS">Months</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col xs={6}>
                                    <Form.Group className="form-group mb-3">
                                      <Form.Label>
                                        Number of{" "}
                                        {
                                          formik.values[
                                          `intervalCurrentTherapyMaximum_${index}`
                                          ]
                                        }
                                      </Form.Label>
                                      <Form.Control
                                        id={`numberOfCurrentTherapyMaximum_${index}`}
                                        name={`numberOfCurrentTherapyMaximum_${index}`}
                                        type="number"
                                        value={
                                          formik.values[
                                          `numberOfCurrentTherapyMaximum_${index}`
                                          ]
                                        }
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          const newCount = e.target.value;
                                          setSelectedTherapyData(
                                            (prevData) => ({
                                              ...prevData,
                                              selectedOptions:
                                                prevData.selectedOptions.map(
                                                  (
                                                    { label, parent, ...rest },
                                                    i
                                                  ) =>
                                                    i === index
                                                      ? {
                                                        ...rest,
                                                        max: {
                                                          ...rest.max,
                                                          count: newCount,
                                                          unit: formik.values[
                                                            `intervalCurrentTherapyMaximum_${index}`
                                                          ]
                                                            ? formik.values[
                                                            `intervalCurrentTherapyMaximum_${index}`
                                                            ]
                                                            : "DAYS",
                                                        },
                                                      }
                                                      : { ...rest }
                                                ),
                                            })
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            )}

                          {selectedOptionCurrentTherapy?.includes(
                            `setTimeToTherapyCheckboxCurrentTherapy_${index}`
                          ) &&
                            "setTimeToTherapyCheckboxCurrentTherapy" && (
                              <div className="therapy-dates-container">
                                <div>
                                  <Form.Label style={{ fontWeight: "900" }}>
                                    Time to therapy
                                  </Form.Label>
                                </div>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  At least
                                </Form.Label>

                                <Row>
                                  <Col xs={6}>
                                    <Form.Group className="form-group mb-3">
                                      <Form.Label>Interval</Form.Label>
                                      <Form.Select
                                        name={`intervalCurrentTimeToTherapyAtLeast_${index}`}
                                        id={`intervalCurrentTimeToTherapyAtLeast_${index}`}
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          const newUnit = e.target.value;

                                          // Set the unit for both min and max when 'At least' interval is changed
                                          setSelectedTherapyData(
                                            (prevData) => ({
                                              ...prevData,
                                              selectedOptions:
                                                prevData.selectedOptions.map(
                                                  (
                                                    { label, parent, ...rest },
                                                    i
                                                  ) =>
                                                    i === index
                                                      ? {
                                                        ...rest,
                                                        timeToMin: {
                                                          ...rest.timeToMin,
                                                          unit: newUnit,
                                                        },
                                                        timeToMax: {
                                                          ...rest.timeToMax,
                                                          unit: newUnit, // Synchronize max unit
                                                        },
                                                      }
                                                      : { ...rest }
                                                ),
                                            })
                                          );

                                          // Update formik value for Maximum interval
                                          formik.setFieldValue(
                                            `intervalCurrentTimeToTherapyMaximum_${index}`,
                                            newUnit
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                          formik.values[
                                          `intervalCurrentTimeToTherapyAtLeast_${index}`
                                          ]
                                        }
                                      >
                                        <option value="DAYS">Days</option>
                                        <option value="WEEKS">Weeks</option>
                                        <option value="MONTHS">Months</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col xs={6}>
                                    <Form.Group className="form-group mb-3">
                                      <Form.Label>
                                        Number of{" "}
                                        {
                                          formik.values[
                                          `intervalCurrentTimeToTherapyAtLeast_${index}`
                                          ]
                                        }
                                      </Form.Label>
                                      <Form.Control
                                        id={`numberOfCurrentTimeToTherapyAtLeast_${index}`}
                                        name={`numberOfCurrentTimeToTherapyAtLeast_${index}`}
                                        type="number"
                                        value={
                                          formik.values[
                                          `numberOfCurrentTimeToTherapyAtLeast_${index}`
                                          ]
                                        }
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          const newCount = e.target.value;
                                          setSelectedTherapyData(
                                            (prevData) => ({
                                              ...prevData,
                                              selectedOptions:
                                                prevData.selectedOptions.map(
                                                  (
                                                    { label, parent, ...rest },
                                                    i
                                                  ) =>
                                                    i === index
                                                      ? {
                                                        ...rest,
                                                        timeToMin: {
                                                          ...rest.timeToMin,
                                                          count: newCount,
                                                          unit: formik.values[
                                                            `intervalCurrentTimeToTherapyMaximum_${index}`
                                                          ]
                                                            ? formik.values[
                                                            `intervalCurrentTimeToTherapyMaximum_${index}`
                                                            ]
                                                            : "DAYS",
                                                        },
                                                      }
                                                      : { ...rest }
                                                ),
                                            })
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Form.Label style={{ fontWeight: "bold" }}>
                                  Maximum
                                </Form.Label>

                                <Row>
                                  <Col xs={6}>
                                    <Form.Group className="form-group mb-3">
                                      <Form.Label>Interval</Form.Label>
                                      <Form.Select
                                        name={`intervalCurrentMTimeToTherapyMaximum_${index}`}
                                        id={`intervalCurrentTimeToTherapyMaximum_${index}`}
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          // console.log("EEEEE",formik.values[`intervalCurrentTimeToTherapyMaximum_${index}`])
                                          const newUnit = e.target.value;
                                          // console.log("UUUU",newUnit)
                                          setSelectedTherapyData(
                                            (prevData) => ({
                                              ...prevData,
                                              selectedOptions:
                                                prevData.selectedOptions.map(
                                                  (
                                                    { label, parent, ...rest },
                                                    i
                                                  ) =>
                                                    i === index
                                                      ? {
                                                        ...rest,
                                                        timeToMax: {
                                                          ...rest.timeToMax,
                                                          unit: newUnit,
                                                        },
                                                      }
                                                      : { ...rest }
                                                ),
                                            })
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                          formik.values[
                                          `intervalCurrentTimeToTherapyMaximum_${index}`
                                          ]
                                        }
                                      >
                                        <option value="DAYS">Days</option>
                                        <option value="WEEKS">Weeks</option>
                                        <option value="MONTHS">Months</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col xs={6}>
                                    <Form.Group className="form-group mb-3">
                                      <Form.Label>
                                        Number of{" "}
                                        {
                                          formik.values[
                                          `intervalCurrentTimeToTherapyMaximum_${index}`
                                          ]
                                        }
                                      </Form.Label>
                                      <Form.Control
                                        id={`numberOfCurrentTimeToTherapyMaximum_${index}`}
                                        name={`numberOfCurrentTimeToTherapyMaximum_${index}`}
                                        type="number"
                                        value={
                                          formik.values[
                                          `numberOfCurrentTimeToTherapyMaximum_${index}`
                                          ]
                                        }
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          const newCount = e.target.value;
                                          setSelectedTherapyData(
                                            (prevData) => ({
                                              ...prevData,
                                              selectedOptions:
                                                prevData.selectedOptions.map(
                                                  (
                                                    { label, parent, ...rest },
                                                    i
                                                  ) =>
                                                    i === index
                                                      ? {
                                                        ...rest,
                                                        timeToMax: {
                                                          ...rest.timeToMax,
                                                          count: newCount,
                                                          unit: formik.values[
                                                            `intervalCurrentTimeToTherapyMaximum_${index}`
                                                          ]
                                                            ? formik.values[
                                                            `intervalCurrentTimeToTherapyMaximum_${index}`
                                                            ]
                                                            : "DAYS",
                                                        },
                                                      }
                                                      : { ...rest }
                                                ),
                                            })
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            )}

                          {selectedOptionCurrentTherapy?.includes(
                            `setByDateCheckboxCurrentTherapy_${index}`
                          ) && (
                              <div className="therapy-dates-container">
                                <div className="row">
                                  <div className="col-6">
                                    <Form.Group className="form-group mb-3">
                                      <Form.Label>
                                        Start Date(Lower Limit)
                                      </Form.Label>
                                      <Form.Control
                                        type="date"
                                        id={`startdateForTherapiesCurrent_${index}`}
                                        name={`startdateForTherapiesCurrent_${index}`}
                                        value={
                                          formik.values[
                                          `startdateForTherapiesCurrent_${index}`
                                          ]
                                        }
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          const newValue = e.target.value;
                                          setSelectedTherapyData((prevData) => ({
                                            ...prevData,
                                            selectedOptions:
                                              prevData.selectedOptions.map(
                                                ({ label, parent, ...rest }, i) =>
                                                  i === index
                                                    ? {
                                                      ...rest,
                                                      startDate: {
                                                        ...rest.startDate,
                                                        from: newValue,
                                                      },
                                                    }
                                                    : { ...rest }
                                              ),
                                          }));
                                        }}
                                        onBlur={formik.handleBlur}
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className="col-6">
                                    {formik.values[
                                      `startdateForTherapiesCurrent_${index}`
                                    ] && (
                                        <Form.Group className="form-group mb-3">
                                          <Form.Label>
                                            Start date(Upper Limit)
                                          </Form.Label>
                                          <Form.Control
                                            type="date"
                                            id={`enddateForTherapiesCurrent_${index}`}
                                            name={`enddateForTherapiesCurrent_${index}`}
                                            value={
                                              formik.values[
                                              `enddateForTherapiesCurrent_${index}`
                                              ]
                                            }
                                            onChange={(e) => {
                                              formik.handleChange(e);
                                              const newValue = e.target.value;
                                              setSelectedTherapyData(
                                                (prevData) => ({
                                                  ...prevData,
                                                  selectedOptions:
                                                    prevData.selectedOptions.map(
                                                      (
                                                        { label, parent, ...rest },
                                                        i
                                                      ) =>
                                                        i === index
                                                          ? {
                                                            ...rest,
                                                            startDate: {
                                                              ...rest.startDate,
                                                              to: newValue,
                                                            },
                                                          }
                                                          : { ...rest }
                                                    ),
                                                })
                                              );
                                            }}
                                            onBlur={formik.handleBlur}
                                            min={
                                              formik.values[
                                              `startdateForTherapiesCurrent_${index}`
                                              ]
                                            }
                                          />
                                        </Form.Group>
                                      )}
                                  </div>
                                </div>
                                {item?.value.includes("Previous") && (
                                  <Row>
                                    <Col xs={6}>
                                      <Form.Group className="form-group mb-3">
                                        <Form.Label>
                                          End Date(Lower Limit)
                                        </Form.Label>
                                        <Form.Control
                                          type="date"
                                          id={`enddateForTherapiesPreviousLowerLimit_${index}`}
                                          name={`enddateForTherapiesPreviousLowerLimit_${index}`}
                                          value={
                                            formik.values[
                                            `enddateForTherapiesPreviousLowerLimit_${index}`
                                            ]
                                          }
                                          onChange={(e) => {
                                            formik.handleChange(e);
                                            const newValue = e.target.value;
                                            setSelectedTherapyData(
                                              (prevData) => ({
                                                ...prevData,
                                                selectedOptions:
                                                  prevData.selectedOptions.map(
                                                    (
                                                      { label, parent, ...rest },
                                                      i
                                                    ) =>
                                                      i === index
                                                        ? {
                                                          ...rest,
                                                          endDate: {
                                                            ...rest.endDate,
                                                            to: newValue,
                                                          },
                                                        }
                                                        : { ...rest }
                                                  ),
                                              })
                                            );
                                          }}
                                          onBlur={formik.handleBlur}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                      {formik.values[
                                        `enddateForTherapiesPreviousLowerLimit_${index}`
                                      ] && (
                                          <Form.Group className="form-group mb-3">
                                            <Form.Label>
                                              End Date(Upper Limit)
                                            </Form.Label>
                                            <Form.Control
                                              type="date"
                                              id={`enddateForTherapiesPreviousUpperLimit_${index}`}
                                              name={`enddateForTherapiesPreviousUpperLimit_${index}`}
                                              value={
                                                formik.values[
                                                `enddateForTherapiesPreviousUpperLimit_${index}`
                                                ]
                                              }
                                              onChange={(e) => {
                                                formik.handleChange(e);
                                                const newValue = e.target.value;
                                                setSelectedTherapyData(
                                                  (prevData) => ({
                                                    ...prevData,
                                                    selectedOptions:
                                                      prevData.selectedOptions.map(
                                                        (
                                                          {
                                                            label,
                                                            parent,
                                                            ...rest
                                                          },
                                                          i
                                                        ) =>
                                                          i === index
                                                            ? {
                                                              ...rest,
                                                              endDate: {
                                                                ...rest.startDate,
                                                                from: newValue,
                                                              },
                                                            }
                                                            : { ...rest }
                                                      ),
                                                  })
                                                );
                                              }}
                                              onBlur={formik.handleBlur}
                                              min={
                                                formik.values[
                                                `enddateForTherapiesPreviousLowerLimit_${index}`
                                                ]
                                              }
                                            />
                                          </Form.Group>
                                        )}
                                    </Col>
                                  </Row>
                                )}
                              </div>
                            )}
                        </div>
                      </Col>
                    );
                  }
                  return null; // If the item does not include "Current", return null
                })}
              </Row>
            </>
          </div>
        )}

        {currentModal === 6 && (
          <div className="Buy_form">
            <>
              <Row>
              <h5
                  style={{
                    paddingTop: "6px",
                    // paddingLeft: "10px",
                    color: "#5B99A6",
                    cursor: "pointer",
                    // position:'absolute'
                  }}
                  onClick={handlePrevious}
                >
                 {'<'} Back
                </h5>
                <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                  Set a Price
                </h2>

                <>
                  <Col lg={6} md={6} xs={12}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Price</Form.Label>
                      <Form.Control
                        name="price"
                        type="number"
                        placeholder="Enter Price"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.price}
                        disabled={
                          formik?.values?.HealthCareProfessional ===
                          user?.user?.id
                        }
                      />
                      {formik.touched.price && formik.errors.price ? (
                        <div className="error">{formik.errors.price}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Currency</Form.Label>
                      <input
                        type="text"
                        id="currency"
                        name="currency"
                        className="form-control"
                        value="€"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        readOnly
                      />
                      {formik.touched.currency && formik.errors.currency ? (
                        <div className="error">{formik.errors.currency}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </>
                <Col xs={12}>
                  <Form.Group className="form-group mb-3">
                    <Form.Label>Information</Form.Label>
                    <div style={{ float: "right" }}>
                      Word Count: {wordCount}/150
                    </div>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="description"
                      placeholder="Enter information..."
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onInput={handleInput}
                      value={formik.values.description}
                    />

                    {formik.touched.description && formik.errors.description ? (
                      <div className="error">{formik.errors.description}</div>
                    ) : null}
                  </Form.Group>
                </Col>

                {/* <Button
                style={{ marginBottom: '38px' }}
                className="buttonBuy"
                type="submit"
                onClick={formik.handleSubmit}
              >
                Request
              </Button> */}
              </Row>
            </>
          </div>
        )}
      </Form>
    );
  };

  return (
    <header id="header">
      <div className="aic">
        <div className="logo">
          <Link to="/">
            <img src={Images.textlogo} alt="LiberDat" />
          </Link>
        </div>

        <div
          className="sb"
          style={{
            marginRight: user?.user?.role === "seller" ? "4vw" : "0px",

            marginLeft: !user?.user?.role ? "4vw" : "0px",
          }}
        >
          {hasSearch && (
            <InputGroup style={{ flexWrap: "nowrap", marginBottom: "0px" }}>
              <FormControl
                className="form-control"
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={(e) => handleSearchChange(e.target.value)}
              />

              <div
                className="dropdown1"
                ref={dropdown1Ref}
                onClick={toggleDropdown}
              >
                <button className="dropdown-toggle1">
                  {selectedMenux}
                  {/* {isOpen ? (
                    <IoIosArrowUp
                      style={{ marginLeft: "2px", alignItems: "center" }}
                      onClick={(e) => {
                        e.stopPropagation(); // Stop the click event propagation
                        toggleDropdown(); // Toggle dropdown
                      }}
                    />
                  ) : (
                    <IoIosArrowDown
                      style={{ marginLeft: "2px" }}
                      onClick={(e) => {
                        e.stopPropagation(); // Stop the click event propagation
                        toggleDropdown(); // Toggle dropdown
                      }}
                    />
                  )} */}
                </button>
                {isOpen && (
                  <div className="dropdown-menu1">
                    <div className="dropdown-item1" onClick={handleDataClick}>
                      <DiDatabase />
                      Data
                    </div>
                    <div className="dropdown-item1" onClick={handleUserClick}>
                      <FaUsers />
                      Users
                    </div>
                  </div>
                )}
              </div>
            </InputGroup>
          )}
        </div>
        <div className="text-end1">
          {isMobile && (
            <div className="side-menu">
              <CiMenuBurger size={25} />
            </div>
          )}

          {!user || user?.user?.role === "buyer" ? (
            <>
              {/* <Link
                style={{
                  textDecoration: "none",
                }}
                to="/buy"
              >
                <button
                  style={{
                    textWrap: "nowrap",
                    textDecoration: "none",
                  }}
                  className="headerbuttons"
                >
                  Request
                </button>
              </Link> */}
              {user?.user?.role==='buyer' && (
                <button className="headerbuttons" onClick={handleRequestClick}>
                  Request
                </button>
              )}
              <Modal
                isOpen={isOpenRequestModal}
                onRequestClose={closeModal}
                style={{
                  overlay: {
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    backdropFilter: "blur(8px)",
                    zIndex: 1000,
                  },
                  content: {
                    width:
                      currentModal === 1
                        ? "40vw"
                        : currentModal === 2
                          ? "40vw"
                          : currentModal === 4
                            ? "40vw"
                            : currentModal === 3
                              ? "40vw"
                              : currentModal === 5
                                ? "40vw"
                                : currentModal === 6
                                  ? "40vw"
                                  : modalWidth,
                    margin: "auto",
                    height:
                      currentModal === 1
                        ? "50vh"
                        : currentModal === 2
                          ? "50vh"
                          : currentModal === 3
                            ? "85vh"
                            : currentModal === 4
                              ? "65vh"
                              : currentModal === 5
                                ? "65vh"
                                : currentModal === 6
                                  ? "65vh"
                                  : "60vh",

                    zIndex: 9999,
                    borderRadius: "25px",
                  },
                }}
              >
                {renderModalContent()}

                <div className="btns">
                  {currentModal == 1 && formik?.values?.medical && (
                    
                    <div className="" style={{width:'100%', marginTop:'100px'}}>
                    <CustomButton
                    // style={{ marginTop:'100px'}}
                    label={"Next"}
                    color={"#5B99A6"}
                    textcolor={"white"}
                    backgroundColor={"#5B99A6"}
                    width="100%"
                    onClick={handleNext}
                  />
                  </div>
                  )}
                  {currentModal == 2 && containEpisode.length !== 0 && (
                    <CustomButton
                      style={{ paddingBottom: "15px" }}
                      label={"Next"}
                      color={"#5B99A6"}
                      textcolor={"white"}
                      backgroundColor={"#5B99A6"}
                      width="100%"
                      onClick={handleNext}
                    />
                  )}

                  {currentModal == 3 && (
                    <CustomButton
                      style={{ paddingBottom: "25px" }}
                      label={"Next"}
                      color={"#fff"}
                      textcolor={"white"}
                      backgroundColor={"#5B99A6"}
                      width="100%"
                      onClick={handleNext}
                    />
                  )}
                  {currentModal === 4 && (
                    <CustomButton
                      style={{ paddingBottom: "25px" }}
                      label={"Next"}
                      color={"#fff"}
                      textcolor={"white"}
                      backgroundColor={"#5B99A6"}
                      width="100%"
                      onClick={handleNext}
                    />
                  )}

                  {currentModal === 5 && (
                    <CustomButton
                      style={{ paddingBottom: "15px" }}
                      label={"Next"}
                      color={"#333333"}
                      type={"submit"}
                      textcolor={"white"}
                      backgroundColor={"#5B99A6"}
                      width="100%"
                      onClick={handleNext}
                    />
                  )}
                  {currentModal === 6 && (
                    <CustomButton
                      style={{ paddingBottom: "15px" }}
                      label={"Request"}
                      color={"#333333"}
                      type={"submit"}
                      textcolor={"white"}
                      backgroundColor={"#5B99A6"}
                      width="100%"
                      onClick={formik.handleSubmit}
                    />
                  )}
                </div>
              </Modal>
            </>
          ) : (
            <></>
          )}
          {/* <Link to="/privacypolicy">
                <Button style={{textWrap: "nowrap"}} variant="outline-dark">Privacy Policy</Button>
              </Link> */}
          {!user && (
            <Link
              style={{
                textDecoration: "none",
              }}
              to="/login"
            >
              <button className="headerbuttons">Login</button>
            </Link>
          )}
          {user ? (
            <div
              className="custom-dropdown"
              ref={dropdownMenuRef}
              onClick={toggleMenu}
            >
              <div className="custom-dropdown-toggle">
                <FaUserCircle size={30} className="position-relative" />
                {/* {console.log("NNN",notificationCount)} */}
                {notificationCount > 0 && (
                  <Badge
                    bg="danger"
                    className="position-absolute top-0 end-0 rounded-pill"
                  >
                    {notificationCount}
                  </Badge>
                )}
              </div>
              {showMenu && (
                <div className="custom-dropdown-menu">
                  <div className="username">
                    <div style={{ fontWeight: "bold" }}>
                      {user?.user?.username}{" "}
                    </div>
                    <div
                      style={{
                        color: "#837e7e",
                        fontSize: "1em",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      {user?.user?.email}{" "}
                    </div>
                  </div>
                  <div
                    className="dropdown-option"
                    onClick={() => navigate(RouteNames.Default.route)}
                  >
                    Home
                  </div>
                  {console.log("UHAHAHA",user?.role)}
{user?.user?.role=='buyer' && (

                  <div
                    className="dropdown-option"
                    onClick={() => navigate(RouteNames.Notifications.route)}
                  >
                    Notifications
                    {notificationCount > 0 && (
                      <Badge bg="danger rounded-pill">
                        {notificationCount}
                      </Badge>
                    )}
                  </div>)}
                  {/* {user.user.role === 'seller' && (
                  <div className="dropdown-option" onClick={() => navigate(RouteNames.AcceptBidReq.route)}>
                      Requests
                  </div>
              )} */}
                  <div
                    className="dropdown-option"
                    onClick={() => navigate(RouteNames.Messages.route)}
                  >
                    Messages
                  </div>
                  <div
                    className="dropdown-option"
                    onClick={() => navigate(RouteNames.Myliberdat.route)}
                  >
                    My Liberdat
                    <div className="divider"></div>
                  </div>
                  <div className="signout-item" onClick={() => handleLogout()}>
                    <AiOutlineLogout />
                    Sign out
                  </div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}

          <div className="language-dropdown" ref={dropdownLanguageRef}>
            <div class="dropdown2">
              <button class="dropdown-toggle2 " onClick={toggleDropdownLan}>
                {selectedLanguage}
                {isOpenLan ? (
                  <IoIosArrowUp style={{ marginLeft: "2px" }} />
                ) : (
                  <IoIosArrowDown style={{ marginLeft: "2px" }} />
                )}
              </button>
              {isOpenLan && (
                <div class="dropdown-menu2">
                  <div
                    className="dropdown-item2"
                    onClick={() => handleLanguageSelect("EN")}
                  >
                    <img src={Images.en} alt="" width={20} />
                    EN
                  </div>
                  <div
                    className="dropdown-item2"
                    onClick={() => handleLanguageSelect("NL")}
                  >
                    <img src={Images.nl} alt="" width={20} />
                    NL
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);

const CustomDropdownTwo = ({
  id,
  name,
  options,
  formik,
  isMulti,
  Institution,
  setInstitution,
}) => (
  <Select
    id={id}
    name={name}
    options={options}
    isMulti={isMulti}
    value={
      isMulti
        ? options.filter((option) => formik.values[name].includes(option.value))
        : options.find((option) => option.value === formik.values[name])
    }
    onChange={(selectedOptions) => {
      const selectedValues = isMulti
        ? selectedOptions.map((option) => option.value)
        : selectedOptions.value;

      formik.setFieldValue(name, selectedValues);

      // Update Institution state only with names
      if (isMulti) {
        setInstitution(selectedOptions.map((option) => option.label));
      } else {
        setInstitution([selectedOptions.label]);
      }
    }}
    onBlur={formik.handleBlur}
  />
);
