import React from "react";
import { Images } from "../../assets/images";
import { Link } from "react-router-dom";
import "./style.scss";

export const WelcomePage = () => {

  return (
    <div className="main-container">
      <img className="coverimage" src={Images.howitworks} alt="sadsa" />
      <div class="text-overlay">
      Free Your Data
         <p style={{    fontSize: 18,
    fontWeight: 400}}>For you, For everybody</p>
        {/* Button Below  */}

       
      </div>
      <div className="explore-button">
          <Link to="/home">
            <button className="button">Explore</button>
          </Link>
        </div>
   
    </div>
  );
};
