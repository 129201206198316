import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGetPredictedDataofUserQuery, useUpdateSellerGraphNotificationMutation, useUpdateThresholdMutation } from "../../store/api";
import { Tooltip } from 'react-tooltip';
import ReactApexChart from "react-apexcharts";
import styles from "./UserPrediction.module.scss";
import CustomButton from "../../components/custombutton/CustomButton";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { AiFillAlert } from "react-icons/ai";
import ThresholdModal from "../../components/thresholdmodal/ThresholdModal";
import "./style.scss";
import CustomGraph from "./CustomGraph";
// import { scatterData } from "./ScatDummyData";

const UserPrediction = () => {
  const location = useLocation();
  const dataFromProbs=location.state?.user;
  const loggedInUser = useSelector((state) => state?.auth?.user?.user);
  const user = dataFromProbs?dataFromProbs:loggedInUser;

  // console.log("User SELLER: ", user);
  // const loggedInUser = useSelector((state) => state.auth?.user?.user);
  const [predictionType, setPredictionType] = useState("Predicted");
  const [dataType, setDataType] = useState("Steps/Day");
  const [thresholdValue, setThresoldValue] = useState('')
  const [showThreshold, setShowThreshold] = useState(false);
  const [notificationSent, setNotificationSent] = useState(false);
  const [notifySeller, { data: dataNotify, isSuccess, isError: isErrorNotify, error }] = useUpdateSellerGraphNotificationMutation();

  const [updateThresold, { isSuccess: thresholdIsSuccess, isError: thresholdIsError }] = useUpdateThresholdMutation()

  // sending the threshold value and Seller id to the backend
  const handleThresholdSubmit = async () => {

    const thresholdData = {
      sellerId: user?._id ? user._id : user?.id,
      threshold: thresholdValue
    }
    console.log("Threshold Data: ", thresholdData)
    await updateThresold(thresholdData)
      .unwrap()
      .then(() => {
        // Success handling
        toast.success('Threshold Update Request sent');
        setShowThreshold(false); // Close modal on success
        setNotificationSent(true);
      })
      .catch(() => {
        // Error handling
        toast.error('Failed to update threshold');
      });
    if (thresholdIsSuccess) {
      toast.success("Threshold updated successfully")
      setShowThreshold(false)
    }
    if (thresholdIsError) {
      toast.error("Failed to update threshold")
    }
  }


  const {
    data: predictedData,
    isLoading,
    isError,
    error: predictionError,
  } = useGetPredictedDataofUserQuery(user?._id ? user._id : user?.id);

  console.log(dataNotify, isSuccess,user?._id,user?.id,dataFromProbs);

  useEffect(() => {

    if (isSuccess) {
      toast.success("Request Sent Successfully")
    }
    if (isErrorNotify) {
      toast.error("Failed to send request")
    }
  }, [isSuccess, isErrorNotify])


  const handleThresholdVisibility = () => {
    setShowThreshold(!showThreshold);
  }


  const getTherapyNames = (data) => {
    const therapyNames = {};
  
    data?.forEach((item) => {
      Object?.keys(item)?.forEach((key) => {
        if (key?.includes('First_Therapy')) {
          therapyNames.first = key.split('_')[1];
        } else if (key?.includes('Second_Therapy')) {
          therapyNames.second = key.split('_')[1];
        } else if (key?.includes('Third_Therapy')) {
          therapyNames.third = key.split('_')[1];
        } else if (key?.includes('Fourth_Therapy')) {
          therapyNames.fourth = key.split('_')[1];
        }
      });
    });
  
    return therapyNames;
  };
  const therapyNames = getTherapyNames(predictedData?.combinedData);
  console.log("predictedData?.combinedData",predictedData)

  return (
    <div className={styles.container}>
      <ThresholdModal visible={showThreshold} handleClose={setShowThreshold} value={thresholdValue} setValue={setThresoldValue} onSubmit={handleThresholdSubmit} />
      <h1 className={styles.header}>Predicted Data</h1>
      <div className={styles.dropdownContainer}>
        <select
          className={styles.select}
          value={dataType}
          onChange={(e) => setDataType(e.target.value)}
        >
          <option value="Steps/Day">Steps/Day</option>

          {/* Check if the "Relapse" keyword is present in the data */}
          {predictedData?.historicalData?.some(item => item.hasOwnProperty("Relapse")) &&
            predictedData?.predictions?.some(item => item.hasOwnProperty("Relapse")) ? (
            <>
              <option value="Places visited / Day">Places visited / Day</option>
              <option value="Relapse">Relapse</option>
            </>
          ) : (
            <option value="Analysis (HbA1c)">HbA1c</option>
          )}
        </select>
<h5 style={{color:'#5c9ba7', marginLeft:'15vw'}}data-tooltip-id="actual-tooltip"
         data-tooltip-content={`Target: ${predictedData?.r2Group?(predictedData?.r2Group * 100).toFixed(2):'-'}% \n Actual: ${predictedData?.r2Inv?(predictedData?.r2Inv * 100).toFixed(1):'-'}%`}>Accuracy</h5>


      </div>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <div className={styles.loader}></div>
        </div>
      ) : isError ? (
        <div>Error loading data</div>
      ) : (
        // <ReactApexChart
        //   options={chartData.options}
        //   series={chartData.series}
        //   type="line"
        //   height={400}
        //   key={`${predictionType}`}
        // />
        <div className='graph-container' style={{width:'80vw', marginLeft:'-6vw', overflow: 'auto',scrollbarWidth: 'none'}}>
        <CustomGraph
        scatterData={predictedData?.combinedData}
        />
        </div>

      )}
      <div className={styles.infoBoxContainer}>
        <p
         data-tooltip-id="actual-tooltip"
         data-tooltip-content="legenda"
        ><span style={{letterSpacing:-2,fontWeight:900,fontSize:20}}>---------</span> Actual<br/><span style={{color:'grey',fontWeight:900,fontSize:20}}>------</span> Target</p>
        
        {predictionType === "Predicted" && (
          <div className={styles.infoBox}>
           <b> Predicted From:</b> Age, Gender, Diagnosis, Age at 
onset, Relapse 
frequency, Time 
to Therapy, Time 
on Therapy, 
Steps
          </div>
        )}

        <div className={styles.infoBox2}  data-tooltip-id="actual-tooltip"
         data-tooltip-content="Nauwkeurigheid van de voorspelling">
          <div>
            {therapyNames?.first && (<div><span style={{ color: 'purple' }}>■   </span>{therapyNames.first} ® 1st</div>)}
            {therapyNames?.second && (<div><span style={{ color: 'orange' }}>■   </span>{therapyNames.second} ® 2nd</div>)}
            {therapyNames?.third && (<div><span style={{ color: 'blue' }}>■   </span> {therapyNames.third} ® 3rd</div>)}
            {therapyNames?.fourth && (<div><span style={{ color: 'green' }}>■   </span>{therapyNames.fourth} ® 4th</div>)}
            {/* {predictionType === "Predicted" && (<div>R²:  <span style={{ color: 'red', marginLeft: '15px' }}>{(predictedData?.r2 * 100).toFixed(2)}</span> </div>)} */}
          </div>
          <span style={{ paddingTop: '0.5rem', cursor: 'pointer' }}>
            Alarm: {user?.threshold? user?.threshold:'not set'}
            <AiFillAlert color="red" size={20} style={{ marginTop: '-5px', marginLeft: '10px' }} onClick={handleThresholdVisibility} />
            {/* {notificationSent ? <span style={{ color: 'green', marginLeft: '15px' }}>Notification Sent</span> : null} */}

          </span>

        </div >

      </div >
      <div className="graphButtons">
        {user?.lastValidatedBy?.includes(loggedInUser?.id) ? <CustomButton
          color={'white'}
          label={'Request Data Update'}
          onClick={() => {

            const vts = {
              sellerId: user?._id ? user._id : user?.id,
            }
            console.log("VTS: ", vts)
            notifySeller(vts)

          }}
        /> : user?._id === loggedInUser?.id ? <button
          style={styles.updateButton}
          onClick={() => toast.success("Please update it through your mobile phone")}
        >
          Update
        </button> : null}
        <p><b>Last updated : </b>
          {new Date(user?.lastFetchedSteps).toDateString()} ({new Date(user?.lastFetchedSteps).toLocaleTimeString()})
        </p>
      </div>
      <Tooltip id="actual-tooltip" style={{backgroundColor:"#156082"}}/>
    </div >
  );
};

export default UserPrediction;
