import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
// import './CustomGraph.css'; // Import CSS file for styles
// import showToast from './ToastAlert';

const screenWidth = window.innerWidth;
const chartHeight = 250;
const displayPoints = 150; // Number of days to display at once

const CustomGraph = ({ scatterData, HistoricLineColor, PredictLineColor }) => {
  const scrollViewRef = useRef(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [selectedItemText, setSelectedItemText] = useState(null);
  const [isDataFetched, setIsDataFetched] = useState(false);
  

  const pastColor = HistoricLineColor || 'black';
  const futureColor = PredictLineColor || 'grey';
  const groupPastColor = 'grey';
  const individualFutureColor = 'black';
  const relapsingRemetingColor='black';
  const secondaryColor='black';
  const HypertensionColor='black';

  const xSpacing = screenWidth / displayPoints;
  const chartWidth = xSpacing * (scatterData?.length || 1);

  const todayIndex = scatterData?.findIndex(item => {
    const dateParts = item.date.split('-');
    const today = new Date();
    return (
      parseInt(dateParts[0]) === today.getDate() &&
      parseInt(dateParts[1]) === today.getMonth() + 1 &&
      parseInt(dateParts[2]) === today.getFullYear()
    );
  });

  useEffect(() => {
    if (scatterData && scatterData.length > 0) {
      setIsDataFetched(true);
    }
  }, [scatterData]);

  useEffect(() => {
    if (isDataFetched && scrollViewRef.current && todayIndex !== -1) {
      const todayX = todayIndex * xSpacing - window.innerWidth / 2;
      scrollViewRef.current.scrollTo({
        left: todayX > 0 ? todayX : 0,
        behavior: 'smooth',
      });
    }
  }, [isDataFetched, todayIndex, xSpacing]);

  const maxSteps = Math.max(
    ...scatterData?.map(item =>
      Math.max(
        item?.dataHistorical || 0,
        item?.groupPredictionHistorical || 0,
        item?.groupPrediction || 0,
        item?.individualPrediction || 0
      )
    )
  ) || 1;

  const points = scatterData?.map((item, index) => ({
    x: xSpacing * index,
    historicalY: item.dataHistorical ? chartHeight - (item.dataHistorical / maxSteps) * chartHeight : null,
    groupHistoricalY: item.groupPredictionHistorical
      ? chartHeight - (item.groupPredictionHistorical / maxSteps) * chartHeight
      : null,
    groupFutureY: item.groupPrediction
      ? chartHeight - (item.groupPrediction / maxSteps) * chartHeight
      : null,
    individualFutureY: item.individualPrediction
      ? chartHeight - (item.individualPrediction / maxSteps) * chartHeight
      : null,
    date: item.date,
    ...item,
  }));

  const handleArrowClick = (x, y, text, color) => {
    setSelectedItemText({ x, y: y - 15, text, color });
    setTimeout(() => setSelectedItemText(null), 9000);
  };

  const yAxisLabels = Array.from({ length: 5 }, (_, i) => ({
    label: Math.round((maxSteps / 4) * i),
    y: chartHeight - (chartHeight / 4) * i,
  }));

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {selectedPoint && (
        <div style={{ paddingBottom: 10 }}>
          <span style={{ fontSize: 14, color: 'black', fontWeight: 'bold' }}>
            Date: {selectedPoint.date}, Data Historical: {selectedPoint.historicalY || 'N/A'},
            Group Historical: {selectedPoint.groupHistoricalY || 'N/A'},
            Group Future: {selectedPoint.groupFutureY || 'N/A'},
            Individual Future: {selectedPoint.individualFutureY || 'N/A'}
          </span>
        </div>
      )}
      <div style={{ width: 38, height: chartHeight + 60, backgroundColor: 'white', paddingVertical: 10 }}>
        <svg height={chartHeight + 60} width={40}>
          <line x1="35" y1="0" x2="35" y2={chartHeight} stroke="black" strokeWidth="2" data-tooltip-id="custom-tooltip"
              data-tooltip-content={`Y-axis: Stappen/dag`}/>
          {yAxisLabels.map((label, index) => (
            <text
              key={`y-label-${index}`}
              x="30"
              y={label.y + 9}
              fontSize="10"
              fill="black"
              textAnchor="end"
              data-tooltip-id="custom-tooltip"
              data-tooltip-content={`Y-axis: Stappen/dag`}
            >
              {label.label}
            </text>
          ))}
        </svg>
      </div>
      <div style={{ height: chartHeight + 60, backgroundColor: 'white', paddingVertical: 10 }}>
        <div
          ref={scrollViewRef}
          style={{ overflowX: 'auto', whiteSpace: 'nowrap', height: chartHeight + 60 }}
        >
          <svg height={chartHeight + 60} width={chartWidth}>
            <line x1="0" y1={chartHeight} x2={chartWidth} y2={chartHeight} stroke="black" strokeWidth="1" data-tooltip-id="custom-tooltip"
            data-tooltip-content={`X-as: Datum`}/>
            {points.map((point, index) => {
              const dateParts = point.date.split('-');
              const formattedDate =
                dateParts[0] % 15 === 0 ? `${dateParts[0]}/${dateParts[1]}/${dateParts[2].slice(-2)}` : '';
              const today = new Date();
              const isToday =
                parseInt(dateParts[0]) === today.getDate() &&
                parseInt(dateParts[1]) === today.getMonth() + 1 &&
                parseInt(dateParts[2]) === today.getFullYear();

              return (
                <React.Fragment key={index}>
                  {isToday && (
                    <text
                      x={point.x + 6}
                      y={chartHeight + 32}
                      fontSize="10.5"
                      fill="black"
                      fontWeight="bold"
                      textAnchor="middle"
                      transform={`rotate(-92, ${point.x}, ${chartHeight + 23})`}
                    //   onClick={() => handleArrowClick(point.x + 30, chartHeight + 17, 'Vandaag', "white")}
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={`Vandaag`}
                    >
                      Today -
                    </text>
                  )}
                  <text
                    x={point.x}
                    y={chartHeight + 11}
                    fontSize="10"
                    fill="black"
                    textAnchor="middle"
                    transform={`rotate(0, ${point.x}, ${chartHeight + 23})`}
                    data-tooltip-id="custom-tooltip"
            data-tooltip-content={`X-as: Datum`}
                  >
                    {formattedDate}
                  </text>
                </React.Fragment>
              );
            })}
            {/* Historical line */}
{points.map((point, index) => {
    if (index === 0 || !points[index - 1].historicalY || !point.historicalY) return null;
    return (
        <line
            key={`historical-line-${index}`}
            x1={points[index - 1].x}
            y1={points[index - 1].historicalY}
            x2={point.x}
            y2={point.historicalY}
            stroke={pastColor}
            strokeWidth="3"
            data-tooltip-id="custom-tooltip"
            data-tooltip-content={`Uw gelopen stappen`}
        />
    );
})}

{/* Group Historical line */}
{points.map((point, index) => {
    if (index === 0 || !points[index - 1].groupHistoricalY || !point.groupHistoricalY) return null;
    return (
        <line
            key={`group-historical-line-${index}`}
            x1={points[index - 1].x}
            y1={points[index - 1].groupHistoricalY}
            x2={point.x}
            y2={point.groupHistoricalY}
            stroke={groupPastColor}
            strokeWidth="3"
            strokeDasharray="5,5" // Dashed pattern
            // onMouseOver={() => handleMouseOver('This is a red circle')}
            data-tooltip-id="custom-tooltip"
            data-tooltip-content={`Uw voorspelde stappen op basis van soortgelijke patiënten`}
            
        />
    );
})}
{/* Group Future line */}
{points.map((point, index) => {
    if (index === 0 || !points[index - 1].groupFutureY || !point.groupFutureY) return null;
    return (
        <line
            key={`group-future-line-${index}`}
            x1={points[index - 1].x}
            y1={points[index - 1].groupFutureY}
            x2={point.x}
            y2={point.groupFutureY}
            stroke={futureColor}
            strokeWidth="3"
            strokeDasharray="5,5" // Dashed pattern
            data-tooltip-id="custom-tooltip"
            data-tooltip-content={`Uw voorspelde stappen op basis van soortgelijke patiënten`}
           
        />
    );
})}

{/* Individual Future line */}
{points.map((point, index) => {
    if (index === 0 || !points[index - 1].individualFutureY || !point.individualFutureY) return null;
    return (
        <line
            key={`individual-future-line-${index}`}
            x1={points[index - 1].x}
            y1={points[index - 1].individualFutureY}
            x2={point.x}
            y2={point.individualFutureY}
            stroke={individualFutureColor}
            strokeWidth="3"
            data-tooltip-id="custom-tooltip"
            data-tooltip-content={`Uw gelopen stappen`}
        />
    );
})}
{points.map((point, index) => {
    const current = point.MultipleSclerosisRelapsingRemitting;
    const prev = points[index - 1]?.MultipleSclerosisRelapsingRemitting || false;

    const isArrowUp = current && !prev; // First 'true' in the streak
    const x = point.x; // X position of the arrow
    const arrowYStart = chartHeight * 0.8; // Starting point of the arrow line
    const diagnosisName = 'Relapsing Remitting'; // Diagnosis name
    const diagnosisColor = relapsingRemetingColor; // Arrow color

    if (isArrowUp) {
        return (
            <React.Fragment key={`arrow-up-fragment-${index}`}>
                {/* Diagnosis Text */}
                <text
                    x={x + 12.4}
                    y={arrowYStart + 8} // Position above the arrow
                    fontSize="10"
                    fill={diagnosisColor}
                    textAnchor="middle"
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={`Moment van diagnose`}
                >
                    {diagnosisName}
                </text>

                {/* Arrow Line */}
                <line
                    x1={x}
                    y1={chartHeight * 1}
                    x2={x}
                    y2={chartHeight * 0.87}
                    stroke={relapsingRemetingColor}
                    strokeWidth="3"
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={`Moment van diagnose`}
                />

                {/* Arrow Polygon */}
                <polygon
                    points={`${x - 5},${chartHeight * 0.96} ${x + 5},${chartHeight * 0.96} ${x},${chartHeight * 1}`}
                    fill={relapsingRemetingColor}
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={`Moment van diagnose`}
                />

               
            </React.Fragment>
        );
    }
    return null;
})}
{points.map((point, index) => {
    const current = point.MultipleSclerosisSecondaryProgressive;
    const prev = points[index - 1]?.MultipleSclerosisSecondaryProgressive || false;
    const next = points[index + 1]?.MultipleSclerosisSecondaryProgressive || false;

    const isArrowUp = current && !prev; // First 'true' in the streak
    const x = point.x; // X position of the arrow
    const arrowYStart = chartHeight * 0.8; // Starting point of the arrow line
    const diagnosisName = 'Secondary Progressive'; // Diagnosis name
    const diagnosisColor = secondaryColor;

    if (isArrowUp) {
        return (
            <React.Fragment key={`arrow-up-fragment-MSSR-${index}`}>
                {/* Diagnosis Text */}
                <text
                    x={x}
                    y={arrowYStart + 8} // Position above the arrow
                    fontSize="10"
                    fill={diagnosisColor}
                    textAnchor="middle"
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={`Moment van diagnose`}
                >
                    {diagnosisName}
                </text>

                {/* Arrow Line */}
                <line
                    x1={x}
                    y1={chartHeight * 1}
                    x2={x}
                    y2={chartHeight * 0.87}
                    stroke={diagnosisColor}
                    strokeWidth="2"
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={`Moment van diagnose`}
                />

                {/* Arrow Polygon */}
                <polygon
                    points={`${x - 5},${chartHeight * 0.96} ${x + 5},${chartHeight * 0.96} ${x},${chartHeight * 1}`}
                    fill={diagnosisColor}
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={`Moment van diagnose`}
                />
            </React.Fragment>
        );
    }

    return null;
})}
{points.map((point, index) => {
    const current = point.HyperTension;
    const prev = points[index - 1]?.HyperTension || false;

    const isArrowUp = current && !prev; // First 'true' in the streak
    const x = point.x; // X position of the arrow
    const arrowYStart = chartHeight * 0.8; // Starting point of the arrow line
    const diagnosisName = 'Hypertension'; // Diagnosis name
    const diagnosisColor = HypertensionColor; // Arrow color

    if (isArrowUp) {
        return (
            <React.Fragment key={`arrow-up-fragment-${index}`}>
                {/* Diagnosis Text */}
                <text
                    x={x + 12.4}
                    y={arrowYStart + 8} // Position above the arrow
                    fontSize="10"
                    fill={diagnosisColor}
                    textAnchor="middle"
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={`Moment van diagnose`}
                >
                    {diagnosisName}
                </text>

                {/* Arrow Line */}
                <line
                    x1={x}
                    y1={chartHeight * 1}
                    x2={x}
                    y2={chartHeight * 0.87}
                    stroke={diagnosisColor}
                    strokeWidth="3"
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={`Moment van diagnose`}
                />

                {/* Arrow Polygon */}
                <polygon
                    points={`${x - 5},${chartHeight * 0.96} ${x + 5},${chartHeight * 0.96} ${x},${chartHeight * 1}`}
                    fill={diagnosisColor}
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={`Moment van diagnose`}
                />

               
            </React.Fragment>
        );
    }
    return null;
})}
{(() => {
    let therapySegments = [];

    // Define colors for therapy tiers (First, Second, Third, etc.)
    const therapyTierColors = {
        First: "purple",
        Second: "orange",
        Third: "blue",
        Fourth: "green",
        Fifth: "red",
    };
    const defaultColor = "grey"; // Fallback color

    // Define vertical offsets for each tier
    const tierYOffsets = {
        First: -8, // Topmost line
        Second: 1, // Slightly lower
        Third: 9, // Further down
        Fourth: 17,
        Fifth: 25,
    };

    points.forEach((point, index) => {
        // Identify all active therapy keys in the current point
        const activeTherapies = Object.keys(point).filter(key =>
            key.includes("Therapy") && point[key]
        );

        activeTherapies.forEach(activeTherapyKey => {
            const therapyTier = activeTherapyKey.split("_")[2]; // Extract the tier (First, Second, etc.)
            const therapyName = activeTherapyKey.split("_")[1]; // Extract the therapy name

            // Find an existing segment for this therapy
            let existingSegment = therapySegments.find(
                segment => segment.name === therapyName && segment.tier === therapyTier
            );

            if (existingSegment) {
                // Extend the existing segment
                existingSegment.end = point.x;
            } else {
                // Create a new segment
                therapySegments.push({
                    name: therapyName,
                    tier: therapyTier,
                    start: point.x,
                    end: point.x,
                });
            }
        });
    });

    const baseYPosition = chartHeight + 30; // Base line level

    return (
        <>
            {therapySegments.map((segment, index) => {
                const color =
                    therapyTierColors[segment.tier] || defaultColor; // Get the tier color or fallback
                const yOffset = tierYOffsets[segment.tier] || 0; // Get Y offset for the tier
                const yPosition = baseYPosition + yOffset; // Adjust Y position

                return (
                    <React.Fragment key={index}>
                        {/* Draw the line */}
                        <line
                            x1={segment.start}
                            y1={yPosition}
                            x2={segment.end}
                            y2={yPosition}
                            stroke={color}
                            strokeWidth="3"
                            data-tooltip-id="custom-tooltip"
                            data-tooltip-content={`Uw medicatie`}
                            
                        />
                        {/* Display therapy name */}
                        <text
                            x={(segment.start + segment.end) / 2}
                            y={yPosition - 5} // Position text above the line
                            fontSize="10"
                            fill={color}
                            textAnchor="middle"
                            data-tooltip-id="custom-tooltip"
                            data-tooltip-content={`Uw medicatie`}
                            
                        >
                            {/* {segment.name} */}
                        </text>
                    </React.Fragment>
                );
            })}
        </>
    );
})()}


          </svg>
        </div>
      </div>
      <Tooltip id="custom-tooltip" style={{backgroundColor:"#156082"}}/>
    </div>
  );
};

export default CustomGraph;
